import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './SavedSearch.css';

const BroadcastReview = ({
    broastcastReview,
    toggleBroadcastReview,
    editDetails,
    selectedOption,
    handleClose,
    documentData,
    valuesArray,
    handleBroatcast,
    selectedValue,
    selectedValue1,
    valuesArray1,
}) => {
    const userDetails = useSelector(state => state.user.userDetail);
    const handleBroatcastApi = () => {
        handleBroatcast();
        toggleBroadcastReview();
    }
    const locationData = editDetails.city;
    const cleanedLocation = (locationData && typeof locationData === 'string')
        ? locationData.replace(/, CA, USA/g, "")
        : '';
    return (
        <div>
            <ToastContainer />
            <Modal
                size="lg"
                show={broastcastReview}
                onHide={toggleBroadcastReview}
                className="special_modal modelparent broadcastreview"
                centered
            >
                <div className="PopUpBorderDiv closeIcons">
                    <Modal.Header closeButton className="team-popup-margin1 teampopup-title savedSearchshare">
                        <Modal.Title className="form-field-label savedSearch">
                            Select agent(s) you want to broadcast<br />{`"${editDetails.name}"`} saved search
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="team-popup-margin1 pb-0">
                        <div>
                            <div className='position-relative savedSearchinput1 mb-3'>
                                <h5 className='mt-4 savedSearch mb-2' style={{ color: "black" }}>Send to</h5>
                                {selectedOption !== "my office" ?
                                <p className='broadcastOption '>{selectedOption}</p>:
                                <p className='broadcastOption '>{selectedOption} ({userDetails.office_address})</p>}
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Cooperating Commission Offer</h5>
                                <p className='broadcastOption '>{documentData.cooperating_commission_request}</p>
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Additional Emails</h5>
                                <ul className='serviceArea1'>
                                    {valuesArray1?.length !== 0 && valuesArray1?.map((value, index) => (
                                        <li key={index}>
                                            {value}
                                        </li>
                                    ))}
                                </ul>

                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Primary Area</h5>
                                <ul className='serviceArea1'>
                                    <li >
                                        {cleanedLocation}
                                    </li>
                                </ul>                                
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Secondary Area</h5>
                                <ul className='serviceArea1'>
                                    {valuesArray?.length !== 0 && valuesArray?.map((value, index) => (
                                        <li key={index}>
                                            {value}
                                        </li>
                                    ))}
                                </ul>
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Subject</h5>
                                <p className='broadcastOption '>{documentData.subject}</p>
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Description</h5>
                                <p className='broadcastOption '>{documentData.description}</p>
                                <h5 className='mt-3 savedSearch' style={{ color: "black" }}>Time Frame</h5>
                                <p className='broadcastOption '>{selectedValue1}</p>
                            </div>
                            <div className='mb-3 d-flex justify-content-between'>
                                <Button className="mb-3 mt-3 sharesSearchbut2" onClick={toggleBroadcastReview}>Revise</Button>
                                <Button className="mb-3 mt-3 sharesSearchbut1" onClick={handleBroatcastApi}>Send</Button>
                            </div>
                        </div>
                    </Modal.Body>
                    {/* <Modal.Footer className="team-popup-margin teampop-footer"></Modal.Footer> */}
                </div>
            </Modal>
        </div>
    );
};

export default BroadcastReview;
